<template>
  <div class="game_bg">
    <back-nav class="title_top" :icon="'img/backb.png'" :textColor="'#111'" :backColor="'#B6F3F1'" />
    <div class="g_cert">
      <img src="img/game/game_img.png" alt="">
    </div>
    <div class="g_cert_">
      <img src="img/game/game_img_2.png" alt="">
    </div>
    <div class="g_cert_3">
      <img src="img/game/game_img_3.png" alt="">
      <div class="text_box">
        <div class="text_bg" style="background-image: url('img/game/bind_phone.png');">
          <button :disabled="indexData.phone_status == 1"
            :class="indexData.phone_status == 1 ? 'cert_disabled' : 'cert_button'" @click="goAppSub('certPhone')">{{
              phone_text }}</button>
        </div>
        <div class="text_bg" style="background-image: url('img/game/bind_name.png');">
          <button :disabled="indexData.is_auth_id_card == 1"
            :class="indexData.is_auth_id_card == 1 ? 'cert_disabled' : 'cert_button'"
            @click="goAppSub('realNameCertification')">{{ auth_master_text }}</button>
        </div>
      </div>
    </div>
    <button class="cert_submit" :disabled="disabled" :class="disabled ? 'cert_disSub' : 'cert_goSub'"
      @click="goCert">申请认证</button>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/game.css"
import BackNav from '@/components/backNav.vue';
import { applyForGod } from "@/api/api";
export default {
  name: 'certifiedGame',
  components: {
    BackNav
  },
  data() {
    return {
      indexData: {}
    };
  },
  computed: {
    disabled() {
      let phone_status = this.indexData.phone_status
      let is_auth_id_card = this.indexData.is_auth_id_card
      // let new_user_status = this.indexData.new_user_status
      // let auth_wechat_status = this.indexData.auth_wechat_status
      if (phone_status == 1&&is_auth_id_card==1) {
        return false
      } else {
        return true
      }
    },
    phone_text() {
      if (this.indexData.phone_status == 0) {
        return "去绑定"
      } else {
        return "已绑定"
      }
    },
    // new_user_text() {
    //   if (this.indexData.new_user_status == 0) {
    //     return "去完成"
    //   } else {
    //     return "已完成"
    //   }
    // },
    auth_master_text() {
      if (this.indexData.is_auth_id_card == 0) {
        return "去实名"
      } else {
        return "已实名"
      }
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.fetchData()
  },
  methods: {
    fetchData() {
      applyForGod().then(response => {
        this.indexData = response.data
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    goCert() {
      this.$router.push({ path: '/gameList' })
    },
    closePage() {
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
      })
    },
    goAppSub(key) {
      let params = '调用'
      console.log(key)
      this.$bridge.callhandler(key, params, (data) => {
        // 处理返回数据
      })
    }

  },
  watch: {
    '$route': "fetchData"
  }
};
</script>
